
const infoData = [
    {
        title: 'DESIGN',
        desc: 'Wir legen besonderes Augenmerk auf die Gestaltung hochwertiger Wohnimmobilien. Unsere Wohnprojekte zeichnen sich durch zeitgenössische und moderne Baustile vereint mit traditionellen Werten aus.'
    },
    {
        title: 'AUSSTATTUNG',
        desc: 'Wir geben Ihnen die Möglichkeit, die Ausstattung Ihrer Neubau-Immobilie mitzugestalten und passen Ausstattungsdetails, wie beispielsweise Fliesen und Parkett oder Bäderausstattung, an Ihre persönlichen Wünsche und Vorstellungen an.'
    },
    {
        title: 'SONDERWÜNSCHE',
        desc: 'Neben den hochwertigen Ausstattungsoptionen, die wir standardmäßig zur Auswahl bieten, setzen wir auf Sonderwunsch auch komplexere Änderungen um.'
    }
]

export default infoData;