
const cards = [
    {
        img: 'https://res.cloudinary.com/dfdon3lw3/image/upload/v1617357375/otterfing/Bild1_pouica.png',
        title: 'Reihenhäuser in Otterfing',
        desc: 'Otterfing liegt mitten im Bayerischen Oberland, einem wegen seiner herausragend schönen Landschaft über die Landesgrenzen hinaus bekannten Fleckchen Erde',
        link: '/otterfing'
    },
    {
        img: 'https://res.cloudinary.com/dfdon3lw3/image/upload/v1617357313/schlei%C3%9Fheimer/Bild1_xi7aki.jpg',
        title: 'City-Apartments in München',
        desc: 'Im Demografie-Bericht der Landeshauptstand München von 2019 wird Feldmoching als einer der zukünftig am stärksten wachsenden Stadtbezirke Münchens beschrieben',
        link: '/schleissheimer'
    },
    {
        img: 'https://res.cloudinary.com/dfdon3lw3/image/upload/v1617357383/unterhaching/Bild1_pobxj4.jpg',
        title: 'Eigentumswohnungen in Unterhaching',
        desc: 'Die 3- bis 4-Zimmerwohnungen des Mehrfamilienhauses bieten Nutzflächen von ca. 81 m² bis ca. 140 m²',
        link: '/unterhaching'
    },
    {
        img: 'https://res.cloudinary.com/dfdon3lw3/image/upload/v1617357315/vaterstetten/Bild1_arowyj.png',
        title: 'Villen im Bauhausstil',
        desc: 'Die Villen erhalten je nach Grundrissvariante fünf bzw. sechs Zimmer und in Wohnraumqualität ausgebaute helle Souterrainräume',
        link: '/vaterstetten'
    }
]

export default cards;