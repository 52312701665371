import React, { useState, useEffect, useRef } from "react";
import { Jumbotron, Row, Col, Image } from "react-bootstrap";
import "./projectTemplate.scss";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import MainDivider from "../divider/mainDivider";

const ProjectTemplate = (props) => {
  const [map, setMap] = useState(null);
  const mapContainer = useRef(null);

  const jumboStyle = {
    backgroundImage: "url(" + props.bgImg + ")",
  };

  useEffect(() => {
    mapboxgl.accessToken =
      "pk.eyJ1IjoicGF1bDA3MTEiLCJhIjoiY2tqYjdxbzZ0MW82MTJwcnVxczRvejMxdCJ9.luQuemMhdAPTQ9TcwrZdbA";
    const initializeMap = ({ setMap, mapContainer }) => {
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/paul0711/cke1dpmlf0sua19nxso3b1zm7",
        center: [props.lon, props.lat],
        zoom: 17,
      });

      map.on("load", () => {
        setMap(map);
        map.resize();
        map.addControl(new mapboxgl.NavigationControl());
        map.addControl(
          new mapboxgl.GeolocateControl({ trackUserLocation: true })
        );
        new mapboxgl.Marker({ color: "#575656", scale: 1.5 })
          .setLngLat([props.lon, props.lat])
          .addTo(map);
      });
    };

    if (!map) initializeMap({ setMap, mapContainer });
  }, [props.lat, props.lon, map]);

  return (
    <div>
      <Jumbotron className="project__template__jumbotron" style={jumboStyle}>
        <p className="project__template__jumbotron__title">{props.title}</p>
        {props.sold && (
          <p className="project__template__jumbotron__sold">{props.sold}</p>
        )}
      </Jumbotron>
      <div className="project__template__section1">
        <p className="project__template__section1__title">
          {props.section1Ttitle}
        </p>
        <p className="project__template__section1__desc">
          {props.section1Desc}
        </p>
      </div>
      <MainDivider />
      <div className="project__template__section2">
        <p className="project__template__section2__title">
          {props.section2Title}
        </p>
        <Row>
          <Col lg={6} md={6} xs={12}>
            <Image
              className="project__template__section2__img"
              src={props.surrondImg1}
            />
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Image
              className="project__template__section2__img"
              src={props.surrondImg2}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} xs={12}>
            <Image
              className="project__template__section2__img"
              src={props.surrondImg3}
            />
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Image
              className="project__template__section2__img"
              src={props.surrondImg4}
            />
          </Col>
        </Row>
      </div>
      <MainDivider />
      <div className="project__template__section3">
        <p className="project__template__section3__title">
          {props.section3Title}
        </p>
        <p className="project__template__section3__desc">
          {props.section3Desc}
        </p>
        <div
          className="project__template__map"
          ref={(el) => (mapContainer.current = el)}
        />
      </div>
      <MainDivider />
      <div className="project__template__section4">
        <p className="project__template__section4__title">
          {props.section4Title}
        </p>
        <p className="project__template__section4__desc">
          {props.section4Desc}
        </p>
        <Row className="text-center">
          <Col lg={6} md={6} xs={12}>
            <Image
              className="project__template__section4__img"
              src={props.descImg1}
            />
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Image
              className="project__template__section4__img"
              src={props.descImg2}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} xs={12}>
            <ul>
              {props.descList1.map((li1) => (
                <li key={li1} className="project__template__section4__li">
                  {li1}
                </li>
              ))}
            </ul>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <ul>
              {props.descList2.map((li2) => (
                <li key={li2} className="project__template__section4__li">
                  {li2}
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </div>
      <MainDivider />
      {props.layoutImgs.length === 0 ? null : (
        <>
          <div className="project__template__section5">
            <p className="project__template__section5__title">Grundrisse</p>
            <Row className="text-center">
              {props.layoutImgs.map((img) => (
                <Col lg={6} md={6} xs={12}>
                  <Image
                    className="project__template__section5__img"
                    src={img}
                  />
                </Col>
              ))}
            </Row>
          </div>
          <MainDivider />
        </>
      )}
      <div className="project__template__section6">
        <p className="project__template__section6__title">
          Sie haben Interesse?
        </p>
        <p className="project__template__section6__subtitle">
          Wir freuen uns, auf Ihre Nachricht!
        </p>
        <Row>
          <Col lg={4} md={4} xs={12}>
            <a href="mailto:sl@projektbau-muenchen-sued.de">
              <i className="far fa-envelope fa-2x"></i> <br />
              <p>sl@projektbau-muenchen-sued.de</p>
            </a>
          </Col>
          <Col lg={4} md={4} xs={12}>
            <a href="mailto:sl@projektbau-muenchen-sued.de">
              <i className="fas fa-user-tie fa-2x"></i> <br />
              <p>Herr Sebastian Leising</p>
            </a>
          </Col>
          <Col lg={4} md={4} xs={12}>
            <a href="tel:+4916096700976">
              <i className="fas fa-phone-alt fa-2x"></i> <br />
              <p>+49 160 96700976</p>
            </a>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ProjectTemplate;
