import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import './projectCards.scss'
import MainButton from '../button/mainButton';
import cards from './projectData';

const ProjectCards = () => {
    return(
        <div className='project__card__container'>
            <Row>
                { cards.map(card => 
                    <Col lg={6} md={6} xs={12} key={card.link}>
                        <Card className='project__card'>
                            <Fade duration={2000}>
                                <Card.Img className='project__card__img' variant="top" src={card.img} />
                            </Fade>
                            <Card.Body>
                                <Card.Title className='project__card__header'>{card.title}</Card.Title>
                                <Card.Text className='project__card__subheader'>{card.desc}</Card.Text>
                                <MainButton
                                    link={card.link}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                )}
            </Row>
        </div>
    )
}

export default ProjectCards;