import React from "react";
import ProjectTemplate from "./projectTemplate";

const Otterfing = () => {
  const path = "https://res.cloudinary.com/dfdon3lw3/image/upload/";
  const pic1 = "v1617357375/otterfing/Bild1_pouica.png";
  const pic2 = "v1617357380/otterfing/Bild2_xttgx6.png";
  const pic3 = "v1617357372/otterfing/Bild3_yvdqod.png";
  const pic4 = "v1617357374/otterfing/Bild4_row4ta.png";
  const pic5 = "v1617357366/otterfing/Bild5_mbctrk.png";
  const pic6 = "v1617357360/otterfing/Bild6_tvg8kh.png";
  const pic7 = "v1617357363/otterfing/Bild7_wswvbk.png";
  return (
    <ProjectTemplate
      bgImg={path + pic1}
      title={"Reihenhäuser in Otterfing"}
      sold={"100% verkauft"}
      section1Ttitle={"Otterfing"}
      section1Desc={
        "Otterfing liegt mitten im Bayerischen Oberland, einem wegen seiner herausragend schönen Landschaft über die Landesgrenzen hinaus bekannten Fleckchen Erde. Eingebettet zwischen sanften Moränenhügeln blickt die Gemeinde im bayerischen Voralpenland auf eine m ehr als tausendjährige Geschichte zurück. Heute erfreut sich Otterfing, wie auch andere Gemeinden im Landkreis Miesbach, zunehmender Beliebtheit. Dafür verantwortlich ist sicherlich die traumhafte Lage zwischen München und den Bergen.Die hervorragende Verkehrsanbindung mit der Bahn oder an das Schnellstraßenetzrücken den Ort zum einen nahe an die Landeshauptstadt München und zum anderen an die beliebten und bekannten Ausflugsziele wie den Tegernsee, den Schliersee oder die Gipfel des Mangfallgebirges. Aktuell leben in der Gemeinde ca. 4.800 Einwohner. Der kleine und feine Ort lässt keine Wünsche offen. Einzelhandel, Handwerksbetriebe, Groß- und Hightechfirmen neben schmucken Bauernhäusern und landwirtschaftlichen Betrieben stehen für eine ausgeglichene Ortsstruktur."
      }
      section2Title={"Umgebung"}
      surrondImg1={path + pic5}
      surrondImg2={path + pic4}
      surrondImg3={path + pic6}
      surrondImg4={path + pic7}
      section3Title={"Beste Infrastruktur und Verkehrsanbindung"}
      section3Desc={
        "Die exzellente Verkehrsanbindung, durch die Autobahn A8 und die ebenso gute Erreichbarkeit mit der Bahn (S-Bahn und Oberlandbahn BOB), ist nur ein Aspekt, der diese Gemeinde als attraktiven Wohnort empfiehlt. Nach ca. 28 Kilometern ist die Landeshauptstadt München erreicht. Das unmittelbare Umland mit seiner geradezu verschwenderischen Natur lädt ein zu Spaziergängen, Wanderung und Radtouren. Die beliebten Freizeitregionen in der oberbayerischen Seenplatte, der Tegernsee, der Schliersee, der Kochel- oder Walchensee sind problemlos in kurzer Zeit zu erreichen."
      }
      lat={47.896289}
      lon={11.663532}
      section4Title={"Objektbeschreibung"}
      section4Desc={
        "Geplant sind sieben real geteilte Reihenhäuser. Bei der Gestaltung der Reihenhäuser wurde die für das Voralpenland typische klare Formensprache mit ruhigen und gering geneigten Dachflächen und ortsüblichen Fassadenmaterialien wie Holz und Putz kombiniert. So erhalten z.B. alle Fenster im ersten Obergeschoss Klapp-Fensterläden aus Holz und die Fassade im 2. Obergeschoss wird umlaufend mit Holzverkleidet. Die nach Süden ausgerichteten Gärten und Terrassen laden zu gemütlichen Stunden im Kreis ihrer Liebsten ein. Das eigene Auto parken Sie auf einem der angebotenen Stellplätze des neuen Carports."
      }
      descImg1={path + pic2}
      descImg2={path + pic3}
      descList1={[
        "real geteilte Grundstücke",
        "Reihenhäuser m it nutzbaren Flächen von ca. 227 bis 250 qm",
        "wärmedämmende Ziegelaußenmauern mit einer Stärke von 36,5 cm",
        "Planung und Ausführung nach EnEV 2016 (Energieausweis in Vorbereitung)",
        "Souterrainräume in Wohnraumqualität ausgebaut private Gärten, großflächige Südterrassen",
        "Schornstein für den Anschluss eines offenen Kamins oder Ofens",
        "hochwertige Holzfenster mit 3-Scheiben-Wärmeschutz-Isolierverglasung",
      ]}
      descList2={[
        "Fußbodenheizung",
        "bodengleiche Duschen in allen Bädern",
        "hochwertige Bad – und Elektro -Ausstattung",
        "massive Treppen mit Holzbelag",
        "Fußbodenbeläge z.B. Parkett, Granit- oder Natursteinfliesen nach Käuferwunsch",
        "Terrassenböden mit Granitplattenbelag",
        "und vieles mehr…",
      ]}
      layoutImgs={[]}
    />
  );
};

export default Otterfing;
