import { BrowserRouter as Router, Route } from 'react-router-dom';
import Navigation from './components/navbar/navigation';
import Footer from './components/footer/footer';
import CookieConsent from './components/cookieConsent/cookieConsent';
import Home from './components/home/home';
import Contact from './components/contact/contact';
import Quality from './components/quality/quality';
import AllProjects from './components/projectCards/allProjects';
import Impressum from './components/legal/impressum';
import Data from './components/legal/data';
import Otterfing from './components/projects/otterfing';
import Schleißheimer from './components/projects/schleißheimer';
import Unterhaching from './components/projects/unterhaching';
import Vaterstetten from './components/projects/vaterstetten';

const App = () => {
  return (
    <Router>
      <Navigation/>
      <CookieConsent/>
      <Route path='/' exact component={Home}/>
      <Route path='/immobilienprojekte' exact component={AllProjects}/>
      <Route path='/kontakt' exact component={Contact}/>
      <Route path='/bauqualität' exact component={Quality}/>
      <Route path='/otterfing' exact component={Otterfing}/>
      <Route path='/schleissheimer' exact component={Schleißheimer}/>
      <Route path='/unterhaching' exact component={Unterhaching}/>
      <Route path='/vaterstetten' exact component={Vaterstetten}/>
      <Route path='/impressum' exact component={Impressum}/>
      <Route path='/datenschutz' exact component={Data}/>
      <Footer/>
    </Router>
  );
}

export default App;
