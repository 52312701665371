import React, { useState, useEffect, useRef } from 'react';
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import './projectMap.scss';

const ProjectMap = () => {

    const [map, setMap] = useState(null);
    const mapContainer = useRef(null);

    const geojson = {
        type: 'FeatureCollection',
        features: [{
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [11.663543, 47.896278],
            name: '/otterfing'
          }
        },
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [11.563174, 48.206145],
            name: '/schleißheimer'
          }
        },
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [11.624806, 48.066765],
            name: '/säulenstr'
          }
        },
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [11.765807, 48.102731],
            name: '/goldregen'
          }
        }]
    };

    useEffect(() => {
        mapboxgl.accessToken = 'pk.eyJ1IjoicGF1bDA3MTEiLCJhIjoiY2tqYjdxbzZ0MW82MTJwcnVxczRvejMxdCJ9.luQuemMhdAPTQ9TcwrZdbA';
        const initializeMap = ({ setMap, mapContainer }) => {
            const map = new mapboxgl.Map({
                container: mapContainer.current,
                style: "mapbox://styles/paul0711/cke1dpmlf0sua19nxso3b1zm7",
                center: [11.576124, 48.137154],
                zoom: 9
            });
    
            map.on("load", () => {
                setMap(map);
                map.resize();
                map.addControl( new mapboxgl.NavigationControl() );
                map.addControl(new mapboxgl.GeolocateControl({ trackUserLocation: true }));
                geojson.features.forEach(function(marker) {
                    const newMarker = new mapboxgl.Marker({ color: "#575656", scale: 1 })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map);
                    newMarker.getElement().addEventListener('click', () => {
                      window.location = marker.geometry.name
                    })
                });
            });
        };
        if (!map) initializeMap({ setMap, mapContainer });
    }, [map, geojson.features]);

    return(
        <div className='project__map' ref={el => (mapContainer.current = el)} />
    )
}

export default ProjectMap;