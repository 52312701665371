import React from 'react';
import ProjectTemplate from './projectTemplate';

const Vaterstetten = () => {
    const path = 'https://res.cloudinary.com/dfdon3lw3/image/upload/'
    const pic1 = 'v1617357315/vaterstetten/Bild1_arowyj.png';
    const pic2 = 'v1617357321/vaterstetten/Bild2_gifapm.png';
    const pic3 = 'v1617357315/vaterstetten/Bild3_xcwczt.png';
    const pic4 = 'v1617357309/vaterstetten/Bild4_t7qfyb.jpg';
    const pic5 = 'v1617357307/vaterstetten/Bild5_orygrx.jpg';
    const pic6 = 'v1617357313/vaterstetten/Bild6_a0zvth.jpg';
    const pic7 = 'v1617357307/vaterstetten/Bild7_nsi9jf.jpg';
    return(
        <ProjectTemplate
            bgImg={path + pic1}
            title={'Villen im Bauhausstil'}
            sold={'100% verkauft'}
            section1Ttitle={'Vaterstetten'}
            section1Desc={'Die vor den Toren der Stadt München gelegene Gemeinde feiert in diesem Jahr ihr 40-jähriges Jubiläum. Tradition und Moderne treffen in dieser Ortschaft aufeinander. Begünstigt durch die S-Bahn-Anbindung und den dadurch zunehmenden Zuzug Münchner Bürger wuchs Vaterstetten in jüngster Zeit zu einem beliebten Wohnort für diejenigen heran, die es bevorzugen, in ländlicher Umgebung zu leben, ohne jedoch auf die Vorzüge der nahe gelegenen Großstadt verzichten zu wollen. Der Trend, hinaus vor die Tore der Stadt zu ziehen, wurde zusätzlich befeuert durch die ständig steigenden Immobilienpreise in der Landeshauptstadt. Im sogenannten Speckgürtel rund um München lebt es sich bedeutend ruhiger und entspannter als in der boomenden City. Zahlreiche Gemeinden, so auch Vaterstetten, haben sich spätestens mit der Anbindung an das Münchner Verkehrsnetz durch die S-Bahn auf diese Entwicklung eingestellt. Alle Schultypen, zahlreiche Kindergärten und Kinderkrippen stehen für die Betreuung und Bildung der Jüngsten bereit. Straßenfeste, Volksfeste, Wochenmärkte, Sportund Kulturveranstaltungen bieten Räume für Begegnungen von Alteingesessenen und Zugezogenen. Dies ist ein engagierter, aktiver Beitrag der Gemeinde und der zahlreichen ortsansässigen Vereine für ein harmonisches und zukunftsorientiertes Miteinander.'}
            section2Title={'Umgebung'}
            surrondImg1={path + pic5}
            surrondImg2={path + pic4}
            surrondImg3={path + pic6}
            surrondImg4={path + pic7}
            section3Title={'Beste Infrastruktur und Verkehrsanbindung'}
            section3Desc={'Unser neues Bauvorhaben liegt in einer idyllischen Anwohnerstraße umgeben von altem Baumbestand. Nach wenigen Schritten ist der S-Bahnhof bereits erreicht. Hier stehen zwei Linien zur Auswahl. Die S4 und die S6 mit der gemeinsamen Endhaltestelle Ebersberg bzw. in Richtung des Voralpenlandes, mit den Endhaltestellen Tutzing (S6) bzw. Geltendorf (S4). Die S-Bahnen bringen Sie in ca. 15 Minuten zum Münchner Ostbahnhof. Mit dem Auto erreichen Sie in ca. 30 Minuten die Münchner Innenstadt. Die nahe Autobahnspange A99 bietet raschen Anschluss an die Autobahn (A8) Richtung Salzburg bzw. in Richtung Flughafen an die Autobahn (A9/A92). Nach kurzem Fußweg finden Sie einen Supermarkt und kleine Läden für die tägliche Versorgung. Darüber hinaus gibt es im fußläufig erreichbaren Gewerbegebiet (TechnoPark) einen weiteren Supermarkt und einen Bäcker. Namhafte Discounter finden Sie in dem neuen Gewerbepark oberhalb des Sportzentrums an der Baldhamer Straße. Mit vielen Kindertagesstätten inklusive Horte und vier Mittagsbetreuungen an den Schulen wird die Gemeinde der wachsenden Nachfrage nach Betreuung der Kleinsten gerecht. Vier Grundschulen und drei weiterführende Schulen, wie Mittel-, Realschule und Gymnasium bilden das durchaus sehenswerte Schulangebot.'}
            lat={48.102350}
            lon={11.765735}
            section4Title={'Moderne Architektur in bester Qualität'}
            section4Desc={'Die Architektur der zwei Villen greift die klare und reduzierte Formensprache des Bauhaus-Stils auf. Auf überflüssige Schnörkel wird verzichtet, stattdessen steht die ideale Ausnutzung der umbauten Flächen und die optimale Gestaltung der Räume im Mittelpunkt. Die hellen, ruhig gestalteten Fassaden der Häuser bilden einen reizvollen Kontrast inmitten des gewachsenen, durch alten Baumbestand geprägten Wohnumfeldes. Dieses Projekt wird den Anforderungen an modernes und zeitgemäßes Wohnen in allen Belangen gerecht. Die Villen erhalten je nach Grundrissvariante fünf bzw. sechs Zimmer und in Wohnraumqualität ausgebaute helle Souterrainräume. Repräsentativ ist der großzügige Wohn- und Essbereich im Erdgeschoss, mit einer Anschlussmöglichkeit für einen offenen Kamin. Vor dem Wohnbereich liegt die großzügige Terrasse in idealer Südwestausrichtung. Das Obergeschoss bietet drei Zimmer und je nach Grundrissvariante ein bzw. zwei Bäder. Ein Studio bzw. Gästezimmer, ein weiteres Bad und eine umlaufende Dachterrasse werden im Dachgeschoss realisiert. Penthouse-Feeling vermittelt die umlaufende Terrasse. Individuelle Wünsche zur Grundrissgestaltung können in einer frühen Planungsphase natürlich noch berücksichtigt werden.'}
            descImg1={path + pic2}
            descImg2={path + pic3}
            descList1={['real geteilte Grundstücke', 'massive Ziegelaußenmauern mit einer Stärke von 36,5 cm', 'Fußbodenheizung in allen Räumen (außer Nutzräume im Souterrain)', 'Abböschungen bieten viel Tageslicht in den Hobbyräumen', 'edle Ausstattung, z.B. Sanitärobjekte der Marke Duravit, Serie Vero Air', 'hochwertige Fenster mit 3-Scheiben-Wärmeschutz-Isolierverglasung', 'hochwertige Fußbodenbeläge wie z.B. Parkett, Granit- oder Natursteinfliesen', 'großzügig bemessene Garagen mit elektrisch betriebenen Toren und separaten Geräteräumen']}
            descList2={['2 exklusive Villen mit nutzbaren Flächen von ca. 320 m2', 'EnEV 2016 – hocheffiziente Brennwertgastherme mit Warmwasserspeicher und Solarthermie-Unterstützung (Energieausweis in Vorbereitung)', 'Souterrainräume in Wohnraumqualität ausgebaut', 'private Gärten, großflächige Terrassen und Dachterrassen', 'bodengleiche Duschen in allen Bädern', 'elektrisch betriebene Rollläden in allen Wohnräumen', 'Terrassenböden mit Granitplattenbelag', 'und vieles mehr...']}
            layoutImgs={[]}
        />
    )
}

export default Vaterstetten;