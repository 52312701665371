import React from "react";
import ProjectTemplate from "./projectTemplate";

const Schleißheimer = () => {
  const path = "https://res.cloudinary.com/dfdon3lw3/image/upload/";
  const pic1 = "v1617357313/schlei%C3%9Fheimer/Bild1_xi7aki.jpg";
  const pic2 = "v1617357320/schlei%C3%9Fheimer/Bild2_ifns2r.jpg";
  const pic3 = "v1617357319/schlei%C3%9Fheimer/Bild3_w30uaa.jpg";
  const pic4 = "v1617357315/schlei%C3%9Fheimer/Bild4_dnbyds.jpg";
  const pic5 = "v1617357314/schlei%C3%9Fheimer/Bild5_hz7h7e.jpg";
  const pic6 = "v1617357314/schlei%C3%9Fheimer/Bild6_z1imcg.jpg";
  const pic7 = "v1617357315/schlei%C3%9Fheimer/Bild7_oaikf6.jpg";
  return (
    <ProjectTemplate
      bgImg={path + pic1}
      title={"City-Apartments in München"}
      sold={null}
      section1Ttitle={"Müchen lockt"}
      section1Desc={
        "Kaum eine andere Stadt in Deutschland ist so beliebt und kann rückblickend auf die letzten Jahre mit einer derartigen Erfolgsgeschichte aufwarten. Immer mehr Menschen wollen in Bayerns Landeshauptstadt leben. Die Stadt München ist einer der attraktivsten Anziehungsorte für Jung und Alt, für Arbeitgeber und Arbeitnehmer, für Investoren und Genießer und zählt zu den besten Wirtschaftsstandorten Deutschlands. Über 91.000 Wirtschaftsunternehmen sind in München ansässig* und jährlich kommen neue Gewerbebetriebe hinzu. Traditionsunternehmen, DAX 30 notierte Unternehmen, Global-Player und eine vitale Gründerszene sorgen für einen dynamischen Mix.Ein Ende des Booms ist nicht absehbar. Das Hamburgische Weltwirtschaftsinstitut(HWWI) sieht München bei den Zukunftsaussichten aller deutschen Städte unter den TOP 3. Die Metropolregion München ist der zweitgrößte Beschäftigungsstandort Deutschlands und die Beschäftigungsraten steigen weiter. Mit diesem anhaltendem Boom wächst selbstverständlich auch die Nachfrage auf dem Wohnungsmarkt. Wohnraum ist begehrt und rar. Steigende Grundstücks- und Immobilienpreise, die zunehmende Verknappung des Wohnraums und wachsende Mietpreise, ob für Langzeit- oder Kurzzeitmieten, bestimmen den Markt und verdeutlichen, München ist eine Investition wert."
      }
      section2Title={"Umgebung"}
      surrondImg1={path + pic5}
      surrondImg2={path + pic4}
      surrondImg3={path + pic6}
      surrondImg4={path + pic7}
      section3Title={"Alles nah – alles da"}
      section3Desc={
        "Im Demografie-Bericht der Landeshauptstand München von 2019 wird Feldmoching als einer der zukünftig am stärksten wachsenden Stadtbezirke Münchens beschrieben. Bis 2040 wird der Bezirk mit einer Zunahme von über 50 Prozent und mehr als 30.000 neuen Einwohnerinnen und Einwohnern ein erhebliches Wachstum zu verzeichnen haben. Wohnraum ist begehrt und rar. Da heißt es bereits selbst oder qualifizierte Fachkräfte und Mitarbeiterinnen und Mitarbeiter – ob langfristig oder auf auf Zeit, aus dem In- oder Ausland – zu sichern."
      }
      lat={48.206135}
      lon={11.563396}
      section4Title={"Ausstattung vom Feinsten"}
      section4Desc={
        "Nicht nur die Zahlen und Fakten des Wirtschaftsstandortes München locken neue Bewohnerinnen und Bewohner an die Isarmetropole, es ist auch das Lebensgefühl, die Sicherheit und das herausragende kulturelle Angebot. Die nahen Alpen und die traumhaften Seen des Voralpenlandes vervollständigen die Attraktavität dieser wunderbaren Stadt."
      }
      descImg1={path + pic2}
      descImg2={path + pic3}
      descList1={[
        "Massive Bauweise in hochdämmendem Ziegelmauerwerk",
        "Lift von KG / TG bis ins Dachgeschoss (oder Terrassengeschoss?)",
        "Fußbodenheizung mit Einzelraumsteuerung",
        "Tiefgarage mit Einzelstellplätzen und innovativem Parksystem für PKW bis zu einer Höhe von 2,0 m und einem Gewicht bis 2,0 to",
      ]}
      descList2={[
        "Haustechnik und Wärmedämmung nach den Anforderungen der EnEV 2016 – KfW 55",
        "Zukunftssichere, umweltfreundliche Heizenergie durch Fernwärme",
        "Hochwertige Bodenbeläge und Sanitärausstattung",
        "Elektrische Rollläden",
      ]}
      layoutImgs={[]}
    />
  );
};

export default Schleißheimer;
