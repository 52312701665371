import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, ImageWithZoom, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import qualityImages from './qualityImages';

const ImgSlider = () => {
    return (
        <Fade duration={2000}>
            { window.innerWidth <= 640 ? (
                <CarouselProvider
                    naturalSlideWidth={90}
                    naturalSlideHeight={64}
                    totalSlides={7}
                    visibleSlides={1}
                >
                    <Slider>
                    {qualityImages.map((image, i) => 
                    <Slide index={i} key={image}>
                        <ImageWithZoom className='quality__img1' src={image} />
                    </Slide>
                    )}
                </Slider>
                <DotGroup />
                <Row className='text-center'>
                    <Col>
                        <ButtonBack><i className="fas fa-arrow-alt-circle-left fa-2x"></i></ButtonBack>
                    </Col>
                    <Col>
                        <ButtonNext><i className="fas fa-arrow-alt-circle-right fa-2x"></i></ButtonNext>
                    </Col>
                </Row>
            </CarouselProvider>
            ) : (
                <CarouselProvider
                    naturalSlideWidth={70}
                    naturalSlideHeight={44}
                    totalSlides={7}
                    visibleSlides={1}
                    >
                        <Slider>
                        {qualityImages.map((image, i) => 
                        <Slide index={i} key={image}>
                            <ImageWithZoom className='quality__img1' src={image} />
                        </Slide>
                        )}
                    </Slider>
                    <Row className='text-center'>
                        <Col>
                            <ButtonBack><i className="fas fa-arrow-alt-circle-left fa-2x"></i></ButtonBack>
                        </Col>
                        <Col>
                            <DotGroup />
                        </Col>
                        <Col>
                            <ButtonNext><i className="fas fa-arrow-alt-circle-right fa-2x"></i></ButtonNext>
                        </Col>
                    </Row>
                </CarouselProvider>
            )}
        </Fade>
    )
}

export default ImgSlider;