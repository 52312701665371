import React from "react";
import { Jumbotron, Row, Col } from "react-bootstrap";
import "./home.scss";
import MainButton from "../button/mainButton";
import HomeCards from "./homeCards";
import MainDivider from "../divider/mainDivider";
import Fade from "react-reveal/Fade";
import MainCard from "../mainCard/mainCard";
import mainCardData from "./mainCardData";

const Home = () => {
  return (
    <div>
      <div className="home__header">
        <Jumbotron className="header__jumbotron">
          <Fade duration={2000}>
            <h1>Wir entwickeln Ihr Zuhause</h1>
            <MainButton
              title={"Unsere Immobilienprojekte"}
              link={"/immobilienprojekte"}
            />
          </Fade>
        </Jumbotron>
      </div>
      <div className="home__section1">
        <p className="home__section1__header">
          Projektbau-München-Süd GmbH &amp; Co. KG
        </p>
        <p className="home__section1__text">
          Die Projektbau München Süd GmbH &amp; Co. KG ist ein inhabergeführtes
          Projektentwicklerunternehmen mit Sitz in Grünwald bei München. Der
          Schwerpunkt der geschäftlichen Tätigkeit unseres Unternehmens, liegt
          in der Planung und der Entwicklung sowie dem Vertrieb von
          Wohnimmobilien im Großraum München. <br /> Im Rahmen unserer
          Entwicklungstätigkeiten arbeiten wir eng mit langjährigen und in der
          Immobilienbranche erfahrenen Partnerunternehmen unserer Gesellschaft
          zusammen. Diese Unternehmen sind sowohl für die bauliche als auch die
          planerische Ausführung unserer Immobilienprojekte zuständig.
        </p>
        <MainButton title={"Unsere Bauqualität"} link={"/bauqualität"} />
      </div>
      <MainDivider />
      <MainDivider />
      <div className="home__card__section">
        <p className="home__card__section__title">Wir bieten Ihnen ...</p>
        <Row>
          {mainCardData.map((data, i) => (
            <Col key={i} lg={4} md={4} xs={12}>
              <MainCard icon={data.icon} title={data.title} desc={data.desc} />
            </Col>
          ))}
        </Row>
      </div>
      <MainDivider />
      <Fade duration={2000}>
        <HomeCards />
      </Fade>
      <MainDivider />
      <div className="home__section2">
        <p className="home__section2__header">Wir suchen Baugrundstücke</p>
        <p className="home__section2__text">
          Für die Entwicklung neuer Wohnprojekte, halten wir fortlaufend
          Ausschau nach geeigneten Baugrundstücken im Großraum von München.{" "}
          <br /> Sowohl Angebote von Maklern, Rechtsanwälten und Architekten als
          auch Angebote direkt vom Eigentümer sind bei uns sehr erwünscht.{" "}
          <br /> Eine seriöse und professionelle Abwicklung beim
          Grundstückseinkauf hat für uns höchste Priorität. <br /> Senden Sie
          uns gerne eine E-Mail oder kontaktieren Sie uns telefonisch.
        </p>
        <a
          className="home__a__button"
          href="mailto:kl@projektbau-muenchen-sued.de"
        >
          Kontaktieren Sie uns
        </a>
      </div>
    </div>
  );
};

export default Home;
