import React from 'react';
import { Button } from 'react-bootstrap';
import './mainButton.scss';
import { withRouter } from 'react-router-dom';

const MainButton = (props) => {

    const routerLink = (event) => {
        event.preventDefault();
        props.history.push(event.target.attributes.href.value)
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    return(
        <Button onClick={routerLink} href={props.link} className='main__button'>
            { !props.title ? (
                'Mehr Infos'
            ):(
               <>{props.title}</>
            )}
        </Button>
    )
}

export default withRouter(MainButton);
