import React from 'react';
import './allProjects.scss';
import ProjectCards from './projectCards';
import ProjectMap from './projectMap';
import MainDivider from '../divider/mainDivider';
import Fade from 'react-reveal/Fade';

const AllProjects = () => {

    return(
        <div className='all__projects__container'>
            <p className='all__projects__header'>Unsere Immobilienprojekte</p>
            <p className='all__projects__subheader'>Bei jedem unserer Projekte setzen wir auf aller höchste Qualität</p>
            <ProjectCards/>
            <MainDivider/>
            <Fade delay={300}>
                <ProjectMap/>
            </Fade>
            
        </div>
    )
}

export default AllProjects;