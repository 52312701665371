import React, { useState, useEffect, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import './footer.scss';
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { withRouter } from 'react-router-dom';

const Footer = (props) => {
    
    const [map, setMap] = useState(null);
    const mapContainer = useRef(null);
    
    const routerLink = (event) => {
        event.preventDefault();
        props.history.push(event.target.attributes.href.value)
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    const logo = 'https://res.cloudinary.com/dfdon3lw3/image/upload/v1617357397/logo_pj0hs7.png'
    
    useEffect(() => {
        mapboxgl.accessToken = 'pk.eyJ1IjoicGF1bDA3MTEiLCJhIjoiY2tqYjdxbzZ0MW82MTJwcnVxczRvejMxdCJ9.luQuemMhdAPTQ9TcwrZdbA';
        const initializeMap = ({ setMap, mapContainer }) => {
            const map = new mapboxgl.Map({
                container: mapContainer.current,
                style: "mapbox://styles/paul0711/cke1dpmlf0sua19nxso3b1zm7",
                center: [11.526238, 48.041869],
                zoom: 16
            });
    
            map.on("load", () => {
                setMap(map);
                map.resize();
                map.addControl( new mapboxgl.NavigationControl() );
                new mapboxgl.Marker({ color: "#575656", scale: 1})
                    .setLngLat([11.526238, 48.041869])
                    .addTo(map);
            });
        };

        if (!map) initializeMap({ setMap, mapContainer });
    }, [map]);

    return(
        <div className='footer__container'>
            <Row className='text-center'>
                <Col lg={4} md={4} xs={12}>
                    <img src={logo} alt="PMS-Logo"/>
                    <p className='footer__img__subtitle'>Ihr Partner für Qualität</p>
                </Col>
                <Col lg={4} md={4} xs={12}>
                    <p className='footer__title'>Links</p>
                    <a onClick={routerLink} href="/">Startseite</a> <br/>
                    <a onClick={routerLink} href="/immobilienprojekte">Immobilienprojekte</a> <br/>
                    <a onClick={routerLink} href="/bauqualität">Bauqualität</a> <br/>
                    <a onClick={routerLink} href="/kontakt">Kontakt</a> <br/>
                    <a onClick={routerLink} href="/impressum">Impressum</a> <br/>
                    <a onClick={routerLink} href="/datenschutz">Datenschutz</a>
                </Col>
                <Col lg={4} md={4} xs={12}>
                    <p className='footer__title'>Kontakt</p>
                    <div ref={el => (mapContainer.current = el)} className='footer__map' />
                    <a href="https://www.google.com/maps/place/Südliche+Münchner+Str.+15,+82031+Grünwald,+Deutschland/@48.0419148,11.523744,17z/data=!3m1!4b1!4m5!3m4!1s0x479ddc13ab325381:0x600f42d6ec71ef9d!8m2!3d48.0419148!4d11.5259327" target="_blank" rel="noreferrer">Südliche Münchner Str. 15, 82031 Grünwald</a> <br/>
                    <a href="tel:+4916096700976">+49 160 96700976</a>
                </Col>
            </Row>
            <p className='footer__copyright'>&#169; 2021 Projektbau München Süd GmbH</p>
        </div>
    )
}

export default withRouter(Footer);