import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import "./homeCards.scss";

const HomeCards = () => {
  const cards = [
    {
      img: "https://res.cloudinary.com/dfdon3lw3/image/upload/v1617357375/otterfing/Bild1_pouica.png",
      title: "Reihenhäuser in Otterfing",
      sold: "100% verkauft",
      link: "/otterfing",
    },
    {
      img: "https://res.cloudinary.com/dfdon3lw3/image/upload/v1617357383/unterhaching/Bild1_pobxj4.jpg",
      title: "Eigentumswohnungen in Unterhaching",
      sold: "80% verkauft",
      link: "/unterhaching",
    },
    {
      img: "https://res.cloudinary.com/dfdon3lw3/image/upload/v1617357313/schlei%C3%9Fheimer/Bild1_xi7aki.jpg",
      title: "City-Apartments in München",
      sold: null,
      link: "/schleissheimer",
    },
  ];
  return (
    <div className="home__cards__container">
      <p className="home__cards__header">Aktuelle Projekte</p>
      <Row>
        {cards.map((card) => (
          <Col lg={4} md={4} xs={12} key={card.link}>
            <a className="home__cards__link" href={card.link}>
              <Card className="home__cards__card">
                <Card.Img
                  className="home__cards__img"
                  variant="top"
                  src={card.img}
                />
                <Card.Body>
                  <Card.Title className="home__cards__title">
                    {card.title}
                  </Card.Title>
                  <Card.Text className="home__cards__sold">
                    {card.sold}
                  </Card.Text>
                </Card.Body>
              </Card>
            </a>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default HomeCards;
