import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import './navigation.scss';
import { withRouter } from 'react-router-dom';

const Navigation = (props) => {  

    const logo = 'https://res.cloudinary.com/dfdon3lw3/image/upload/v1617357397/logo_pj0hs7.png'

    const routerLink = (event) => {
        event.preventDefault();
        props.history.push(event.target.attributes.href.value)
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        const mobileNav = document.getElementById('basic-navbar-nav');
        mobileNav.classList.remove('show')
    }
    
    return(
        <Navbar expand="lg">
            <Navbar.Brand href="/">
                <img
                    alt="PMS-Logo"
                    src={logo}
                    className="d-inline-block align-top navbar__logo"
                />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                    <Nav.Link onClick={routerLink} href="/">Startseite</Nav.Link>
                    <Nav.Link onClick={routerLink} href="/immobilienprojekte">Immobilienprojekte</Nav.Link>
                    <Nav.Link onClick={routerLink} href="/bauqualität">Bauqualität</Nav.Link>
                    <Nav.Link onClick={routerLink} href="/kontakt">Kontakt</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default withRouter(Navigation);
