import React from 'react';
import './quality.scss';
import { Row, Col } from 'react-bootstrap';
import Divider from '../divider/mainDivider';
import ImgSlider from './qualityImgSlider';
import infoData from './infoData';
import MainCard from '../mainCard/mainCard';

const Quality = () => {

    return(
        <div className='quality__container'>
            <p className='quality__section__title'>Bauqualität</p>
            <p className='quality__section__desc'>Wenn Sie sich für eine Neubau-Immobilie der Projektbau München Süd entscheiden, entscheiden Sie sich für eine anspruchsvolle, zeitgenössische Architektur mit hoher Bauqualität. Wir bieten Ihnen die Möglichkeit ein Zuhause zu schaffen, welches auf Ihre individuellen Wünsche und Anforderungen zugeschnitten ist. Wir verstehen es, anspruchsvolle Baukunst und traditionelle Werte miteinander zu kombinieren, sodass diese das Design und die Formsprache unserer Immobilien definieren. Die Projektbau München Süd realisiert funktionale Wohn- und Lebensräume, welche den Wohnkomfort der späteren Bewohner maximieren sollen. 
            </p>
            <div className='quality__row__container'>
                <Row>
                    { infoData.map((data, i) =>
                        <Col lg={4} md={4} xs={12} key={i}>
                            <MainCard key={i} title={data.title} desc={data.desc}/>
                        </Col>
                    )}
                </Row>
            </div>
            <Divider/>
            <ImgSlider/>
        </div>
    )
}

export default Quality;