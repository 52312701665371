import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { Row, Col } from 'react-bootstrap';
import './cookiesConsent.scss';
 
const cookies = new Cookies();

const CookieConsent = () => {
    const [show, setShow] = useState(false)

    useEffect(() => {
        const consent = cookies.get('consent')
        if (!consent) { setShow(true) }
    }, [show])

    const setCookie = (value) => {
        const expire = new Date();
        expire.getFullYear(expire.getFullYear() + 1)
        cookies.set('consent', value, { expire })
    }

    const acceptCookies = () => {
        setCookie('accepted')
        window.location = ''
    }

    const declineCookies = () => {
        setCookie('declined')
        setShow(false)
    }

    return(
        <div id="consent__container" className={`${!show ? ' hidden' : ''}`}>
            <div className="consent__content">
                <p className="consent__header">Cookie-Hinweis</p>
                <p className="consent__text">Diese Website speichert Cookies auf Ihrem Computer. Diese Cookies werden verwendet, um Ihre Website-Erfahrung zu optimieren und Ihnen einen persönlich auf Sie zugeschnittenen Service bereitstellen zu können, sowohl auf dieser Website als auch auf anderen Medienkanälen. Mehr Infos über die von uns eingesetzten Cookies finden Sie in unserer<a href="/datenschutz"> Datenschutzerklärung</a>. Bei Ihrem Besuch auf unserer Seite werden Ihre Daten nicht verfolgt! Damit Sie diesen Hinweis nicht jedes mal erneut angezeigt bekommen, müssen wir dennoch einen kleinen Cookie speichern. Damit werden aber keine personenbezogenen Daten von Ihnen assoziert.</p>
                <hr/>
                <Row className='consent__button__group justify-content-center'>
                    <Col lg={4} md={4} xs={12}>
                        <button className='consent__button__decline' onClick={declineCookies}>Nur essentielle Cookies</button>
                    </Col>
                    <Col lg={4} md={4} xs={12}>
                        <button className='consent__button__accept' onClick={acceptCookies}>Akzeptieren</button>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default CookieConsent;