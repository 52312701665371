const Bild1 = 'https://res.cloudinary.com/dfdon3lw3/image/upload/v1617357389/bauqualita%CC%88t/Dachterrasse_aaq7yr.jpg';
const Bild2 = 'https://res.cloudinary.com/dfdon3lw3/image/upload/v1617357347/bauqualita%CC%88t/DG-Wohnung-aktuell_f9gktj.jpg';
const Bild3 = 'https://res.cloudinary.com/dfdon3lw3/image/upload/v1617357366/bauqualita%CC%88t/DG-Wohnung_ljqklu.jpg';
const Bild4 = 'https://res.cloudinary.com/dfdon3lw3/image/upload/v1617357329/bauqualita%CC%88t/EG-Wohnung-aktuel_t169wf.jpg';
const Bild5 = 'https://res.cloudinary.com/dfdon3lw3/image/upload/v1617357377/bauqualita%CC%88t/Forsterstrasse_ejjdbt.jpg';
const Bild6 = 'https://res.cloudinary.com/dfdon3lw3/image/upload/v1617357345/bauqualita%CC%88t/Ost_hlkqip.jpg';
const Bild7 = 'https://res.cloudinary.com/dfdon3lw3/image/upload/v1617357351/bauqualita%CC%88t/Ottobrunn_xfbqee.jpg';


const qualityImages = [Bild1, Bild2, Bild3, Bild4, Bild5, Bild6, Bild7]

export default qualityImages;