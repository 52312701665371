import React from 'react';
import { Card } from 'react-bootstrap';
import './mainCard.scss';

const MainCard = (props) => {
    return(
        <Card className='main__card'>
            <Card.Body>
                { !props.icon ? (
                    null
                ) : (
                    <div className='main__card__icon'><i className={props.icon}></i></div>
                )}
                <Card.Title className='main__card__title'>{props.title}</Card.Title>
                <Card.Text className='main__card__desc'>{props.desc}</Card.Text>
            </Card.Body>
        </Card>
    )
}

export default MainCard;