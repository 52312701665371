import React, { useState, useEffect, useRef } from 'react';
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import './contact.scss';
import { Row, Col } from 'react-bootstrap';

const Contact = () => {
    
    const [map, setMap] = useState(null);
    const mapContainer = useRef(null);
    const logo = 'https://res.cloudinary.com/dfdon3lw3/image/upload/v1617357397/logo_pj0hs7.png'

    useEffect(() => {
        mapboxgl.accessToken = 'pk.eyJ1IjoicGF1bDA3MTEiLCJhIjoiY2tqYjdxbzZ0MW82MTJwcnVxczRvejMxdCJ9.luQuemMhdAPTQ9TcwrZdbA';
        const initializeMap = ({ setMap, mapContainer }) => {
            const map = new mapboxgl.Map({
                container: mapContainer.current,
                style: "mapbox://styles/mapbox/streets-v11",
                center: [11.526147, 48.041839],
                zoom: 17
            });
    
            map.on("load", () => {
                setMap(map);
                map.resize();
                map.addControl( new mapboxgl.NavigationControl() );
                map.addControl(new mapboxgl.GeolocateControl({ trackUserLocation: true }));
                new mapboxgl.Marker({ color: "#575656", scale: 1.5})
                    .setLngLat([11.526147, 48.041839])
                    .addTo(map);
            });
        };
        if (!map) initializeMap({ setMap, mapContainer });
    }, [map]);

    return(
        <div>
            <div className='contact__bg__image'>
                <p className='contact__title'>Nehmen Sie mit uns Kontakt auf</p>
                <p className='contact__subtitle'>Wir helfen Ihnen gerne weiter</p>
            </div>
            <div className='contact__container'>
                <Row className='contact__row justify-content-center'>
                    <Col className='contact__col__mail' lg={5} md={5} xs={12}>
                        <a href="mailto:kl@projektbau-muenchen-sued.de">
                            <i className="far fa-envelope"></i> <br/>
                            <p>kl@projektbau-muenchen-sued.de</p>
                        </a>
                    </Col>
                    <Col className='contact__col__phone' lg={5} md={5} xs={12}>
                        <a href="tel:+4916096700976">
                            <i className="fas fa-phone"></i> <br/>
                            <p>+49 160 96700976</p>
                        </a>
                    </Col>
                </Row>
            </div>
            <div className='contact__container__map'>
                <Row className='contact__row__map'>
                    <Col lg={9} md={6} xs={12}>
                        <div className='contact__map' ref={el => (mapContainer.current = el)} />
                    </Col>
                    <Col className='contact__map__desc' lg={3} md={6} xs={12}>
                        <img className='contact__map__desc__img' src={logo} alt="PMS-Logo"/>
                        <p className='contact__map__desc__contact'><b>Unser Büro</b></p>
                        <p className='contact__map__desc__address'>Südliche Münchner Str. 15</p>
                        <p className='contact__map__desc__address'>82031 Grünwald</p>
                        <p className='contact__map__desc__address'>Deutschland</p>
                        <p className='contact__map__desc__contact'><b>Telefon</b></p>
                        <a className='contact__map__desc__link' href='tel:+491718166001'>+49 171 8166001</a>
                        <p className='contact__map__desc__contact'><b>E-Mail</b></p>
                        <a className='contact__map__desc__link' href="mailto:kl@projektbau-muenchen-sued.de">kl@projektbau-muenchen-sued.de</a>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Contact;
