const mainCardData = [
    {
        icon: 'far fa-gem',
        title: 'Qualität',
        desc: 'Wir setzen auf eine termingerechte, qualitativ hochwertige und professionelle Umsetzung unserer Projekte und Bauvorhaben.'
    },
    {
        icon: 'far fa-star',
        title: 'Erfahrung',
        desc: 'Unsere Fachkompetenz, beruht auf über fünfunddreißig Jahren Erfahrung im Bereich der Immobilienprojektentwicklung und dem Vertrieb von Wohnimmobilien in München, Dresden, Leipzig und Berlin.'
    },
    {
        icon: 'far fa-handshake',
        title: 'Beratung',
        desc: 'Die serviceorientierte und professionelle Beratung unserer Kaufinteressenten ist ein wesentlicher Bestandteil unserer Unternehmensphilosophie.'
    }
]

export default mainCardData;