import React from 'react';
import ProjectTemplate from './projectTemplate';

const Unterhaching = () => {
    const path = 'https://res.cloudinary.com/dfdon3lw3/image/upload/'
    const pic1 = 'v1617357383/unterhaching/Bild1_pobxj4.jpg';
    const pic2 = 'v1617357381/unterhaching/Bild2_tcwqvc.jpg';
    const pic3 = 'v1617357378/unterhaching/Bild3_i64ct7.jpg';
    const pic4 = 'v1617357377/unterhaching/Bild4_hlxuuf.jpg';
    const pic5 = 'v1617357376/unterhaching/Bild5_i0soxt.jpg';
    const pic6 = 'v1617357367/unterhaching/Bild6_e584xq.jpg';
    const pic7 = 'v1617357377/unterhaching/Bild7_dmjlne.jpg';
    return(
        <ProjectTemplate
            bgImg={path + pic1}
            title={'Eigentumswohnungen in Unterhaching'}
            sold={'80% verkauft'}
            section1Ttitle={'City-Nah im grünen wohnen'}
            section1Desc={'Unterhaching ist eine moderne, aufgeschlossene Gemeinde, die von ihrer hervorragenden Lage vor den Toren Münchens profitiert. „Wir führen Menschen zusammen”, so lautet das sympathische Motto der Gemeinde, das am Brunnen vor dem Rathaus neben dem Gemeindewappen verewigt wurde. Unterhaching hat viel zu bieten und Sie müssen in der Gemeinde auf nichts verzichten. Ausgedehnte Naturflächen, wie der Perlacher Forst oder der Landschaftspark Hachinger Tal mit seinen 126 ha, garantieren einen hohen Freizeit- und Erholungswert. Namhafte Arbeitgeber, eine ausgezeichnete Infrastruktur sowie zahlreiche Sport- und Kulturangebote kennzeichnen Unterhaching als eine der attraktivsten Gemeinden im Süden der Großstadt. Dank umsichtiger und vorausschauender Planung zur Entwicklung der Gemeinde, wie zum Beispiel die Investition in eine Geothermieanlage, ist Unterhaching bestens für die Zukunft gewappnet. Die Gemeinde bietet damit ihren Bürgern eine zukunftsweisende Alternative zur Wärmeversorgung mit Erdöl oder Erdgas – preisstabil, umweltfreundlich und sicher.'}
            section2Title={'Umgebung'}
            surrondImg1={path + pic5}
            surrondImg2={path + pic4}
            surrondImg3={path + pic6}
            surrondImg4={path + pic7}
            section3Title={'Beste Infrastruktur und Verkehrsanbindung'}
            section3Desc={'Ruhige gelegen in einer schwach befahrenen Nebenstraße mit gepflegte Umgebungsbebauung aus Ein- und Mehrfamilienhäusern. Eine Vielzahl von Geschäften des täglichen Bedarfs, Ärzte, Apotheke und vieles mehr finden Sie in der Münchner Straße nur wenige Schritte entfernt. Im Westen fast vor der Haustüre liegt der Perlacher Forst nur 3 Min. entfernt. Das Zentrum von Unterhaching mit seinem modernen Rathaus ist nach ca. 1 km erreicht. Als Wirtschaftsstandort mit Tradition und Zukunft werden Unterhaching und die umliegenden Gemeinden sehr geschätzt. MBB und die daraus hervorgegangenen Firmen DASA, EADS und Airbus Group machten die Region als Hochtechnologie-Standort bekannt. Hier finden sich namhafte Unternehmen aus Luft- und Raumfahrt, Energie, Sicherheitstechnik, Satellitenkommunikation und Halbleitertechnik wie z.B. der Firmensitz der Infineon Technologies AG (großes Bild) oder der Technologie- und Innovationspark (TIP), die Universität der Bundeswehr München sowie Europas größte Fakultät für Luft-, Raumfahrt und Sicherheit – der Ludwig Bölkow Campus. Unterhaching ist ein attraktives und gleichzeitig verkehrstechnisch günstiges Drehkreuz zwischen der Landeshauptstadt, den Alpen und dem Flughafen München. Die Autobahnen A8 und A99 sorgen für eine gute Anbindung an das Fernstraßennetz. Der Standort Unterhaching wird damit den Ansprüchen an die moderne Logistik gerecht.'}
            lat={48.072753}
            lon={11.607212}
            section4Title={'Objektbeschreibung'}
            section4Desc={'Das Projekt besteht aus einem Mehrfamilienhaus mit nur 5 Wohnungen, einem Einfamilienhaus und einer gemeinsamen Tiefgarage. Die 3- bis 4-Zimmerwohnungen des Mehrfamilienhauses bieten Nutzflächen von ca. 81 m² bis ca. 140 m² und durchdachte, funktionale Grundrisse. Große, zumeist bodentiefe Fenster sorgen für viel Tageslicht. Die Erdgeschosswohnungen erhalten jeweils einen Garten zur privaten Nutzung. Die Wohnungen im Obergeschoss haben Balkone. Im Dachgeschoss entsteht eine sehr großzügige 3-Zimmer-Wohnung auf einer Ebene. Diese exklusive Wohnung verfügt über eine Dachterrasse nach Süden und kann optional mit einem Kaminanschluss ausgestattet werden.'}
            descImg1={path + pic2}
            descImg2={path + pic3}
            descList1={['Massive Bauweise in hochdämmendem Ziegelmauerwerk', 'Zukunftssichere, umweltfreundliche Heizenergie durch Fernwärme (Geothermie Unterhaching)', 'Hochwertige Bodenbeläge', 'Fußbodenheizung mit Einzelraumsteuerung', 'Tiefgarage mit Einzelstellplätzen und innovativem Parksystem für PKW bis zu einer Höhe von 2,0 m und einem bis 2,0 to (auf Sonderwunsch bis 2,6 to), Anschluss von Ladestationen für E-Autos möglich']}
            descList2={['Haustechnik und Wärmedämmung nach den Vorgaben für das KfW Effizienzhaus 55', 'Lift von KG / TG bis ins Dachgeschoss', 'Exklusive Badausstattung, z.B. Sanitärobjekte von Duravit Serie Vero Air, Unterputz-Armaturen, bodengleiche Duschen mit Ganzglaswänden', 'Hochwertige Innentüren, stumpf einschlagend, mit Design-Edelstahlbeschlägen']}
            layoutImgs={[]}
        />
    )
}

export default Unterhaching;